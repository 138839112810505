.fade-messages-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-messages-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 180ms, transform 180ms;
}
.fade-messages-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-messages-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 180ms, transform 180ms;
}
