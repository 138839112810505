/* LOGIN STYLING */
[data-amplify-authenticator] {
  /* amplify login core styling */
  --amplify-components-authenticator-router-background-color: rgba(
    80,
    80,
    80,
    0.85
  );
  --amplify-components-authenticator-router-box-shadow: var(
    --amplify-shadows-xl
  );
  --amplify-components-authenticator-router-border-width: var(
    --amplify-border-widths-xl
  );
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium)
    var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: #137010; /* Lighter Green */
  --amplify-components-button-primary-hover-background-color: #062904; /* Darker Green */
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px #137010; /* Lighter Green */
  --amplify-components-tabs-item-active-border-color: #137010; /* Lighter Green */
  --amplify-components-tabs-item-color: #555555; /* Text color on dark background */
  --amplify-components-tabs-item-active-color: #137010; /* Lighter Green */

  /* amplify link button */
  --amplify-components-button-link-color: #ffffff;
  --amplify-components-button-link-hover-color: #137010; /* Lighter Green */

  /* login text */
  --amplify-components-text-color: #ffffff;
  --amplify-components-heading-color: #ffffff;
  --amplify-components-field-label-color: #ffffff;
  --amplify-components-input-color: #ffffff;
  --amplify-components-passwordfield-button-color: #ffffff;
}

body {
  background-color: #000000; /* Dark gray background color */
}

/* FONT STYLING INFO */
@font-face {
  font-family: "Orbitron";
  src: local("Orbitron-VariableFont_wght"),
    url("./fonts/Orbitron-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "KodeMono";
  src: local("KodeMono-VariableFont_wght"),
    url("./fonts/KodeMono-VariableFont_wght.ttf") format("truetype");
}

/* App Animations */
.fade-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  /* 
  
  Hide completely, instantly.
  This is because otherwise, as of 96987c6 (c. 198),
  switching core screens does the following:

  - Begins unmounting old component
  - Starts mounting new component BEFORE old component is unmounted
  - Results in 2 components being displayed simutaneously
  
  */
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
  transform: scale(0);
  transition: opacity 0s, transform 0s;
}
.fade-exit-active {
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
  transform: scale(0);
  transition: opacity 0s, transform 0s;
}
