.fade-purchasing-enter {
  opacity: 0;
  transform: scale(0.9);
}
.fade-purchasing-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 180ms, transform 180ms;
}
.fade-purchasing-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-purchasing-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 180ms, transform 180ms;
}
